var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"mt-0 main main--small"},[(_vm.$route.meta.title)?[_c('div',{staticClass:"flex mb-10 w-full"},[_c('Title',{attrs:{"align":"center"}},[_c('h1',[_vm._v("Create campaign")]),_c('span',{staticClass:"title__label"},[_vm._v(_vm._s(_vm.$route.meta.title))])])],1),_c('div',{staticClass:"flex mb-12 w-full"},[_c('Steps',{attrs:{"items":[
          {
            label: 'Campaign settings',
            url: '/create/campaign/settings',
          },
          {
            label: 'Streamers',
            url: '/create/campaign/streamers',
            allowed: _vm.stepTwo,
          },
          {
            label: 'Content',
            url: '/create/campaign/content',
            allowed: _vm.stepThree,
          } ]}})],1)]:_vm._e(),_c('router-view')],2)}
var staticRenderFns = []

export { render, staticRenderFns }