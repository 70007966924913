<template>
  <main class="mt-0 main main--small">
    <template v-if="$route.meta.title">
      <div class="flex mb-10 w-full">
        <Title align="center">
          <h1>Create campaign</h1>
          <span class="title__label">{{ $route.meta.title }}</span>
        </Title>
      </div>

      <div class="flex mb-12 w-full">
        <Steps
          :items="[
            {
              label: 'Campaign settings',
              url: '/create/campaign/settings',
            },
            {
              label: 'Streamers',
              url: '/create/campaign/streamers',
              allowed: stepTwo,
            },
            {
              label: 'Content',
              url: '/create/campaign/content',
              allowed: stepThree,
            },
          ]"
        />
      </div>
    </template>

    <router-view />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateCampaign",

  metaInfo: {
    title: "Create Campaign",
  },

  components: {
    Title: () => import("@/components/Title"),
    Steps: () => import("@/components/Steps"),
  },

  computed: {
    ...mapGetters(["getCampaign"]),

    /**
     * @summary go to campaign step 2
     * @returns {boolean} allowed
     */
    stepTwo() {
      if (
        this.getCampaign?.title &&
        this.getCampaign?.displayFreq &&
        this.getCampaign?.brand?.id &&
        this.getCampaign?.brand?.title
      ) {
        return true;
      }
      return false;
    },

    /**
     * @summary go to campaign step 3
     * @returns {boolean} allowed
     */
    stepThree() {
      if (this.stepTwo && this.getCampaign?.handles?.length) {
        return true;
      }
      return false;
    },
  },

  created() {
    if (this.getUserInfo?.type === "streamer") {
      this.$router.push("/");
    }
  },
};
</script>
